import { NewBulkParcelRequest } from "@/models/NewBulkParcelRequest";
import { AxiosPromise } from "axios";
import { BulkParcelsCreatedResponse } from "@/models/BulkParcelsCreatedResponse";
import axiosClient from "@/axios";
import { BulkParcel } from "@/models/BulkParcel";
import { BulkParcelsResponse } from "@/models/BulkParcelsResponse.ts";
import { BulkParcelsParams } from "@/models/BulkParcelsParams";
import { EditBulkParcelRequest } from "@/models/EditBulkParcelRequest";
import { BulkParcelsUpdatedResponse } from "@/models/BulkParcelsUpdatedResponse";

const bulkParcelsService = {
  list(params: BulkParcelsParams): AxiosPromise<BulkParcelsResponse> {
    return axiosClient.get("/bulk-parcels", { params });
  },
  get(id: string | number): AxiosPromise<BulkParcel> {
    return axiosClient.get(`/bulk-parcels/${id}`);
  },
  create(
    request: NewBulkParcelRequest
  ): AxiosPromise<BulkParcelsCreatedResponse> {
    const formData = new FormData();

    if (request.file) {
      formData.append("file", request.file);
    }

    formData.append("description", `${request.description}`);

    const headers = { "Content-Type": "multipart/form-data" };

    return axiosClient.post("/bulk-parcels", formData, { headers });
  },
  update(
    request: EditBulkParcelRequest
  ): AxiosPromise<BulkParcelsUpdatedResponse> {
    return axiosClient.put(`/bulk-parcels/${request.id}`, {
      description: request.description,
    });
  },
};

export default bulkParcelsService;
