
import { defineComponent } from "vue";
import DatepickerInput from "@/components/form/DatepickerInput.vue";
import SearchInput from "@/components/form/SearchInput.vue";
import DefaultButton from "@/components/ui/DefaultButton.vue";
import Modal from "@/components/ui/Modal.vue";
import CreateBulkParcelsModal from "@/components/bulk-parcels/CreateBulkParcelsModal.vue";
import Pagination from "@/components/ui/Pagination.vue";
import { BulkParcel } from "@/models/BulkParcel";
import bulkParcelsService from "@/services/bulk-parcels.service";
import downloadService from "@/services/download.service";
import BulkParcelListItem from "@/components/bulk-parcels/BulkParcelListItem.vue";
import { PaginationMeta } from "@/models/PaginationMeta";
import Spinner from "@/components/ui/Spinner.vue";
import { BulkParcelsParams } from "@/models/BulkParcelsParams";
import _ from "lodash";
import EditBulkParcelsModal from "@/components/bulk-parcels/EditBulkParcelsModal.vue";

interface State {
  showModal: boolean;
  bulkParcels: Array<BulkParcel>;
  isLoading: boolean;
  pagination?: PaginationMeta;
  search: string;
  dateFrom: string;
  dateTo: string;
  editingBulkParcelId: string;
  editingBulkParcelDescription: string;
}

export default defineComponent({
  name: "BulkOrdersList",
  components: {
    EditBulkParcelsModal,
    Spinner,
    Pagination,
    BulkParcelListItem,
    CreateBulkParcelsModal,
    Modal,
    DefaultButton,
    SearchInput,
    DatepickerInput,
  },
  data(): State {
    return {
      showModal: false,
      bulkParcels: [] as BulkParcel[],
      isLoading: true,
      pagination: undefined,
      search: "",
      dateFrom: "",
      dateTo: "",
      editingBulkParcelId: "",
      editingBulkParcelDescription: "",
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    downloadTemplate() {
      downloadService.getTemplate();
    },
    editBulkParcel(id: string, description: string) {
      this.editingBulkParcelId = id;
      this.editingBulkParcelDescription = description;
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
      this.editingBulkParcelId = "";
    },
    bulkParcelEditCompleted() {
      this.showModal = false;
      this.editingBulkParcelId = "";
      this.getList(
        this.pagination?.current_page || 1,
        this.pagination?.per_page || 10
      );
    },
    bulkParcelCreateCompleted() {
      this.showModal = false;
      this.getList(
        this.pagination?.current_page || 1,
        this.pagination?.per_page || 10
      );
    },
    dateFromUpdate(newDateFrom: string) {
      this.dateFrom = newDateFrom;
      this.getList();
    },
    dateToUpdate(newDateTo: string) {
      this.dateTo = newDateTo;
      this.getList();
    },
    searchUpdate(newValue: string) {
      this.search = newValue;
    },
    async getList(page = 1, perPage = 10) {
      this.isLoading = true;

      const params = _.pickBy({
        page: page,
        per_page: perPage,
        search: this.search,
        date_from: this.dateFrom,
        date_to: this.dateTo,
      }) as BulkParcelsParams;

      const { data } = await bulkParcelsService.list(params);

      this.bulkParcels = data.data || [];
      this.pagination = data.meta;
      this.isLoading = false;
    },
  },
});
