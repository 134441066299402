<template>
  <div
    class="w-full justify-center bg-white text-light text-black rounded p-4 cursor-pointer text-justify relative mb-2"
    @click="isExpanded = !isExpanded"
  >
    <input type="checkbox" class="absolute top-6 right-6 md:hidden" />

    <div
      class="w-full flex flex-col md:flex-row justify-center md:justify-between"
    >
      <div class="md:w-1/12">
        <div class="hidden md:block w-20 pt-1">
          <expand-button
            v-model="isExpanded"
            @click="isExpanded = !isExpanded"
          />
        </div>
      </div>

      <div class="mb-4 md:mb-0 md:w-1/12">
        <div class="font-bold text-gray-8 text-light mb-2 md:hidden">
          {{ $t("bulk_parcels.list.item.id") }}
        </div>
        <div class="text-black text-regular md:text-light leading-10">
          {{ $t("bulk_parcels.list.item.id_label", { id: bulkParcel.id }) }}
        </div>
      </div>

      <div class="mb-4 md:mb-0 md:w-1/6">
        <div class="font-bold text-gray-8 text-light mb-2 md:hidden">
          {{ $t("bulk_parcels.list.item.created") }}
        </div>
        <div class="text-black text-regular md:text-light leading-10">
          {{
            moment(bulkParcel.created_at, "YYYY-MM-DD HH:mm").format(
              "DD.MM.YYYY HH:mm"
            )
          }}
        </div>
      </div>

      <div class="mb-4 md:mb-0 md:w-1/12">
        <div class="font-bold text-gray-8 text-light mb-2 md:hidden">
          {{ $t("bulk_parcels.list.item.shipment") }}
        </div>
        <div class="text-black text-regular md:text-light leading-10">
          {{
            $t("bulk_parcels.list.item.parcels_count", {
              count: bulkParcel.parcels_count,
            })
          }}
        </div>
      </div>

      <div class="mb-4 md:mb-0 md:w-1/6">
        <div class="font-bold text-gray-8 text-light mb-2 md:hidden">
          {{ $t("bulk_parcels.list.item.parcels_with_errors") }}
        </div>
        <div
          class="text-black text-regular md:text-light leading-10"
          :class="!!bulkParcel.parcels_with_errors_count && 'text-red-600'"
        >
          {{
            $t("bulk_parcels.list.item.parcels_with_errors_value", {
              count: bulkParcel.parcels_with_errors_count,
            })
          }}
        </div>
      </div>

      <div class="mb-4 md:mb-0 md:w-1/6">
        <div class="font-bold text-gray-8 text-light mb-2 md:hidden">
          {{ $t("bulk_parcels.list.item.status") }}
        </div>
        <div
          class="mt-1 px-3 md:text-light font-semibold capitalize float-left bg-gray-300 rounded-full leading-8"
        >
          {{ bulkParcel.status }}
        </div>
      </div>

      <div class="mb-4 md:mb-0 w-full md:w-1/6 md:flex md:flex-row">
        <default-button
          type="outline"
          class="w-full md:hidden mb-2"
          @click.stop="downloadService.getLabels(bulkParcel.id)"
        >
          <span class="icon icon-label text-big l mr-2" />
          {{ $t("bulk_parcels.list.item.buttons.label") }}
        </default-button>

        <default-button
          type="empty"
          class="hidden md:flex"
          @click.stop="downloadService.getLabels(bulkParcel.id)"
        >
          <span class="icon icon-label text-big l mr-2" />
          {{ $t("bulk_parcels.list.item.buttons.label") }}
        </default-button>

        <default-button
          type="outline"
          class="w-full md:hidden"
          @click.stop="downloadService.getCsv(bulkParcel.id)"
        >
          <span class="icon icon-csv text-big mr-2" />
          {{ $t("bulk_parcels.list.item.buttons.csv_file") }}
        </default-button>

        <default-button
          type="empty"
          class="hidden md:flex md:w-96"
          @click.stop="downloadService.getCsv(bulkParcel.id)"
        >
          <span class="icon icon-csv text-big mr-2" />
          {{ $t("bulk_parcels.list.item.buttons.csv_file") }}
        </default-button>
      </div>

      <div class="hidden md:flex md:flex-row md:justify-end w-1/12">
        <input type="checkbox" />
      </div>
    </div>

    <div v-if="isExpanded">
      <div class="block w-full bg-gray-200 h-0.5 my-4" />
      <div class="flex flex-row mb-2">
        <p class="md:w-1/12" />
        <p class="md:w-2/3 italic">
          {{
            $t("bulk_parcels.list.item.details.description", {
              description: bulkParcel.description,
            })
          }}
        </p>
        <p class="md:w-1/4 text-right">
          <span
            class="text-primary-blue"
            @click.stop="
              $emit(
                'editBulkParcel',
                bulkParcel.id.toString(),
                bulkParcel.description
              )
            "
          >
            {{ $t("bulk_parcels.list.item.buttons.edit") }}
            <span class="icon icon-edit ml-2" />
          </span>
        </p>
      </div>
      <div class="flex flex-row mb-2">
        <p class="hidden lg:block lg:w-1/12" />
        <p class="w-1/2 lg:w-1/4 font-semibold">
          {{
            $t("bulk_parcels.list.item.details.parcels_created_correctly", {
              count:
                bulkParcel.parcels_count - bulkParcel.parcels_with_errors_count,
            })
          }}
        </p>
        <p class="w-1/2 lg:w-1/4 font-semibold">
          {{
            $t("bulk_parcels.list.item.details.parcels_with_errors", {
              count: bulkParcel.parcels_with_errors_count,
            })
          }}
        </p>
        <p class="hidden lg:block lg:w-5/12" />
      </div>
      <div
        class="flex flex-row mb-2 justify-end"
        v-if="!!bulkParcel.parcels_with_errors_count"
      >
        <router-link
          :to="{ name: 'bulk_parcels.errors', params: { id: bulkParcel.id } }"
        >
          <button class="error-button py-1.5 px-5 bg-gray-200 rounded">
            {{ $t("bulk_parcels.list.item.buttons.errors") }}
            <span class="icon-arrow-right text-xs ml-2 mt-1" />
          </button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import moment from "moment";
import DefaultButton from "@/components/ui/DefaultButton.vue";
import downloadService from "@/services/download.service";
import ExpandButton from "@/components/ui/ExpandButton";

export default defineComponent({
  name: "BulkParcelListItem",
  components: {
    ExpandButton,
    DefaultButton,
  },
  emits: ["editBulkParcel"],
  props: {
    bulkParcel: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      moment,
      downloadService,
      isExpanded: false,
    };
  },
});
</script>

<style scoped lang="scss">
@import "../../assets/styles/variables.scss";

.error-button {
  color: $color-primary-blue;
}
</style>
