
import { defineComponent } from "vue";
import DefaultButton from "@/components/ui/DefaultButton.vue";
import bulkParcelsService from "@/services/bulk-parcels.service";

export default defineComponent({
  name: "CreateBulkParcelsModal",
  components: { DefaultButton },
  emits: ["bulkParcelCreateCompleted", "closeModal"],
  data(): { file?: File; description: string; isLoading: boolean } {
    return {
      file: undefined,
      description: "",
      isLoading: false,
    };
  },
  methods: {
    handleFileUpload(event: Event) {
      const target = event.target as HTMLInputElement;

      if (target.files) {
        this.file = target.files[0];
      }
    },
    submitForm() {
      if (!this.file) {
        return;
      }

      this.isLoading = true;

      bulkParcelsService
        .create({ file: this.file, description: this.description })
        .then(() => this.$emit("bulkParcelCreateCompleted"))
        .finally(() => (this.isLoading = false));
    },
  },
  computed: {
    fileName(): string {
      return !this.file ? "" : this.file.name;
    },
  },
});
