
import { defineComponent } from "vue";
import DefaultButton from "@/components/ui/DefaultButton.vue";
import bulkParcelsService from "@/services/bulk-parcels.service";

export default defineComponent({
  name: "EditBulkParcelsModal",
  components: { DefaultButton },
  emits: ["bulkParcelEditCompleted", "closeModal"],
  props: {
    bulkParcelId: {
      type: String,
      required: true,
    },
    bulkParcelDescription: {
      type: String,
      required: true,
    },
  },
  data(): { description: string; isLoading: boolean; error: string } {
    return {
      description: this.bulkParcelDescription,
      isLoading: false,
      error: "",
    };
  },
  methods: {
    submitForm() {
      this.isLoading = true;
      this.error = "";

      bulkParcelsService
        .update({
          id: this.bulkParcelId,
          description: this.description,
        })
        .then(() => this.$emit("bulkParcelEditCompleted"))
        .catch((error) => {
          if (error?.response?.status === 422) {
            switch (error.response.data.details.description[0]) {
              case "must be filled":
                this.error = "Description must be filled";
                break;
              case "size cannot be greater than 2000":
                this.error = "Description must be shorter than 2000 characters";
            }
          }
        })
        .finally(() => (this.isLoading = false));
    },
  },
});
