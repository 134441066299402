import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "mb-6 md:flex md:flex-row" }
const _hoisted_2 = { class: "mb-4 md:w-1/6" }
const _hoisted_3 = { class: "text-regular text-black" }
const _hoisted_4 = { class: "md:w-3/6 pt-1" }
const _hoisted_5 = {
  key: 0,
  class: "w-full items-center mt-2"
}
const _hoisted_6 = { class: "text-center text-red-500 font-semibold" }
const _hoisted_7 = { class: "mt-2 md:flex md:flex-row md:justify-center md:mt-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_default_button = _resolveComponent("default-button")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.$t("bulk_parcels.edit.description")), 1)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _withDirectives(_createElementVNode("textarea", {
          id: "description",
          class: "rounded bg-white border-gray-4 border w-full h-36 text-black p-2",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.description) = $event)),
          onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.error = ''))
        }, null, 544), [
          [
            _vModelText,
            _ctx.description,
            void 0,
            { trim: true }
          ]
        ]),
        (_ctx.error)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.error), 1)
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createVNode(_component_default_button, {
        type: "outline",
        class: "w-full mb-2 md:w-auto",
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('closeModal')))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t("general.cancel")), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_default_button, {
        class: "w-full md:w-auto md:ml-4",
        onClick: _ctx.submitForm,
        "is-loading": _ctx.isLoading
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t("bulk_parcels.edit.buttons.edit")), 1)
        ]),
        _: 1
      }, 8, ["onClick", "is-loading"])
    ])
  ]))
}